

.colum-container{
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.titlecc{
    width: 25%;
    font-size: 1.1rem;
}

.progress-container{
    width: 60%;
    height: 40%;
    background-color: aliceblue;
    margin-right: 2%;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 510px){
    .colum-container{
        justify-content: space-between;
    }

    .titlecc{
        margin-left: 2%;
    }

    .progress-container{
        margin-right: 5%;
    }

    .progress-container{
        width: 60%;
        height: 30%;
    }
}

