* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    color: black;
    overflow-x: hidden;
    font-size: 16px;
    background-image: url("./bg-body.jpg");
    --bgpink:#cf455c;
    --gray: #656565;
    font-family: Inter,sans-serif;
}

html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.swiper-pagination-bullet {
    background-color: white !important;
    opacity: 1;
  }

.swiper-pagination-bullet-active {
    background-color: var(--bgpink) !important;
}

@media screen and (max-width: 510px) {
    html,body{
        height: 100vh;
        width: 100vw;
    }
}
