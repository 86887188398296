.project-container{
    height: 100%;
    width: 100%;
    margin: auto;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    color: black;
}

.project-content{
    width: 30%;
    height: 100%;
    background-color: whitesmoke;
    padding-left: 20px;
}

.project-visual{
    width: 70%;
    height: 100%;
    background-color: var(--gray);
}

.project-contenth1{
    height: 25%;
    font-size: 28px;
    display:flex ;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
    color: var(--bgpink);
}

.project-contentp{
    height: 50%;
    font-size: 17px;
    font-weight: 600;
    background-color: #b9b9b9;
    display: flex;
    align-items: center;
    padding: 5px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    line-height: 22px;

}

.project-btn-container{
    height: 25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.project-btn-container a{
    background-color: var(--bgpink);
    font-size: 13px;
    text-decoration: none;
    color: whitesmoke;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 70px;
    border: none;
}

.pj-img-size1{
    max-width: 100%;
    min-height: 100%;
}

@media screen and (max-width:1050px) {
    .project-contenth1{
        font-size: 25px;
        color: var(--bgpink);
        padding-left: initial;
    }
}

@media screen and (max-width:950px) {
    .project-contenth1{
        font-size: 20px;
        padding-left: 0px;
    }
}

@media screen and (max-width:510px) {
    .project-container{
        height: 100%;
        width: 100%;
        margin: initial;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }
    
    .project-content{
        width: 90%;
        max-height: 50%;
        padding-left: 5%;
        margin-left: 5%;
        background-color: initial;
        color: #2E2B2A;
        position: relative;
    }

    .project-contenth1{
        height: 25%;
        font-size: 40px;
        padding-left: 5px;
        color: #2E2B2A;
        margin-top: 10px;
    }
    
    .project-contentp{
        height: 50%;
        width: 90%;
        font-size: 30px;
        font-weight: 600;
        background-color: initial;
        border: initial;
        line-height: initial;
    
    }
    
    .project-btn-container{
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;       
        width: 90%;
        position: absolute;
        bottom: 0px;
    }

    .project-btn-container a{
        background-color: hsl(250, 69%, 61%);
        background-color: var(--bgpink);
        font-size: 30px;
        text-decoration: none;
        color: whitesmoke;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .project-visual{
        width: 90%;
        max-height: 250px;
        background-color: var(--gray);
        margin-left: 5%;
    }

    .pj-img-size1{
        min-width: 100%;
        min-height: 100%;
    }
}

@media screen and (max-width:400px) and (max-height:785px) {
    .project-contenth1{
        height: 20%;
        font-size: 35px;
    }
    
    .project-contentp{
        min-height: 40%;
        width: 100%;
        font-size: 25px;
        font-weight: 600;
    }
    
    .project-btn-container{
        height: 60px;
        width: 50%;
    }

    .project-btn-container a{
        font-size: 25px;
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width:300px) and (max-height:750px) {
    .project-contenth1{
        height: 20%;
        font-size: 30px;
    }
    
    .project-contentp{
        min-height: 40%;
        width: 100%;
        font-size: 22px;
        font-weight: 600;
    }
    
    .project-btn-container{
        height: 45px;
        width: 50%;
    }

    .project-btn-container a{
        font-size: 25px;
        height: 100%;
        width: 100%;
    }

    .project-visual{
        width: 90%;
        max-height: 180px;
        background-color: var(--gray);
        margin-left: 5%;
    }

    .pj-img-size1{
        min-width: 100%;
        min-height: 100%;
    }
}

@media screen and (max-width:300px) and (max-height : 680px) {
    .project-visual{
        max-height: 180px;
    }
}