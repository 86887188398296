.appmenu-main{
    height: 74px;
    background-color:whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.linkmain{
    text-align: center;
    text-decoration: none;
}

@media screen and (max-width: 510px){
    .appmenu-main{
        height: 100px;
    }
}

@media screen and (max-width: 300px){
    .appmenu-main{
        height: 80px;
    }
}