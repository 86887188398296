@keyframes dpanimate {
    0%{ border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;}
    50%{ border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;}
    100%{border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;}
}

.App-content{
    height: 80%;
    background-color: var(--bgpink);
    color: white;
    display: flex;
    flex-direction: row;
}

.dpcontainer{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#dp{
    width: 48%;
    height: 60%;
    /*border: 2px solid black;*/
    animation-name: dpanimate;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    overflow: hidden;
}

#pimage{
    max-height: 120%;
    position: relative;
    top: -15px;
}

.smallintro{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#smallintro-h1{
    min-height: 25%;
    font-size: 3.5rem;
    font-weight: 500;
    text-align: center;
}

#smallintro-h3{
    font-weight: 600;
    font-size: 0.9rem;
    text-align: end;
    max-width: 90%;
}

#smallintro-p{
    margin-top: 10%;
    font-size: 1.1rem;
    word-spacing: 3px;
    letter-spacing: 1px;
}

@media screen and (min-width : 1650px){
    #pimage{
        top: -20px;
        right: 15px;
    }
}

@media screen and (min-height : 1000px){
    #pimage{
        position: relative;
        max-height: 100%; 
        max-width: 110%;  
    }

    #dp{
        min-width: 70%;
        max-height: 50%;
    }
}

@media screen and (min-height : 770px){
    #pimage{
        max-height: 118%;
        top: -18px;
        left: -20px;
    }

    #dp{
        width: 48%;
        height: 50%;
    }

}

@media screen and (min-height : 720px){
    #pimage{
        max-height: 115%;
        top: -18px;
        left: -5px;
        
    }
    #dp{
        width: 48%;
        height: 50%;
    }    
}

@media screen and  (min-height:630px) and (max-height : 719px){
    #pimage{
        max-height: 115%;
        top: -18px; 
        left: -15px;    
    }
    #dp{
        width: 48%;
        height: 60%;
    }    
}

@media screen and (min-height:590px)
{
    #pimage{
        max-height: 115%;
        top: -18px; 
        left: -20px;    
    }
    #dp{
        width: 48%;
        height: 60%;
    }  
}

@media screen and (max-width: 510px){
    .App-content{
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
    }

    .dpcontainer{
        height: 40%;
        width: 80%;
    }

    #pimage{
        max-height: 120%;  
        left: 1px;
    }

    .smallintro{
        height: 60%;
        width: 100%;
    }

    #smallintro-h1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 5%;
        font-size: 3.5rem;
    }

    #smallintro-h3{
        font-size: 1.2rem;
        font-weight: 600;
        min-height: 10%;
        max-width: 95%;
    }

    #smallintro-p{
        font-size: 1.5rem;
        font-weight: 400;
        padding-left: 5%;
    }

    .dpcontainer{
        align-items: flex-end;
        margin: auto;
    }

    #dp{
        width: 65%;
        height: 80%;
    }
}


@media screen and (max-width: 420px)
{
    #pimage{
        max-height: 120%; 
        left: -10px;
    }
}

@media screen and (max-width: 385px)
{
    #pimage{
        max-height: 120%; 
    }

    #dp{
        width: 60%;
    }
}

@media screen and (max-width: 376px){
    #pimage{
        max-height: 120%; 
        left: -10px;
    }
    #dp{
        width: 50%;
    }
}

@media screen and (max-width: 300px){

    #pimage{
        max-height: 120%; 
        left: -10px;
    }
    #dp{
        width: 65%;
        height: 80%;
    }

    .App-content{
        height: calc(100% - 80px);
        display: flex;
        flex-direction: column;
    }

    #smallintro-h1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 5%;
        font-size: 3rem;
    }

    #smallintro-h3{
        font-size: 0.8rem;
        font-weight: 600;
        min-height: 10%;
        max-width: 95%;
    }

    #smallintro-p{
        font-size: 1.15rem;
        font-weight: 400;
        padding-left: 5%;
    }
}