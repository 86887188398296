@media screen and (max-width: 510px){
    .Skillsection{
        flex-direction: column;
        height: 100%;
    }

    .Sheading-container{
        flex-direction: row;
        width: 100%;
        height: 35%;
    }

    .skillcontent{
        width: 100%;
        height: 55%;
        overflow-y: scroll;
    }

    .Sheading-container-icon{
        width: 30%;
        text-align: center;
        font-size: 4rem;
    }
}

@media screen and (max-width: 300px){
    .Sheading-container{
        flex-direction: row;
        width: 100%;
        height: 30%;
    }

    .Sheading-container-h1{
        font-size: 2rem;
    }

    .Sheading-container-icon{
        font-size: 3rem;
    }
}