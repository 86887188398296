.Portfolio-container{
    height: 80%;
    display: flex;
    flex-direction: column;
    border: 10px solid whitesmoke;
    overflow-y: scroll;
    background-color: whitesmoke;
    scroll-behavior: smooth;
}

.Portfolio-container::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width:510px) {
    .Portfolio-container{
        width: 100%;
        border: none;
        height: calc(100vh - 100px);
        background-color: initial;
    }
}

@media screen and (max-width: 300px){

    .Portfolio-container{
        height: calc(100% - 80px);
        display: flex;
        flex-direction: column;
    }
}