.Contactmain-container{
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content:space-between;
    background-color: #efefef;
    
}

.contact-form-container{
    width: 65%;
    height: 95%;
    box-shadow: 3px 2px 8px gray , -3px -2px 8px gray;
}

#contact-form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #2E2B2A;
    color: white;
}

.contact-form-section{
    height: 25%;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.contact-form-section-row{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    height: 100%;
}

.contact-form-message{
    width: 94%;
    margin: auto;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#message{
    height: 70%;
    resize: none;
}

#button-container{
    height: 10%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-button{
    margin-left: 2.9%;
    width: 15%;
    height: 100%;
    background-color: var(--bgpink);
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 1rem;
    border: none;
    margin-right: 2.9%;
}

#form-button:hover{
    cursor: pointer;
}

/*      contact info section            */

#contactinfo-container{
    width: 32%;
    height: 90%;
    margin-top: 2.5%;
}

#contactinfo-h1{
    font-size: 1.9rem;
    text-align: center;
    color: var(--bgpink);
}

.icon-data-container{
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.icon-data-container-i{
    font-size: 1.5rem;
    color: var(--bgpink);
    min-width: 11%;
}

.icon-data-container-p{
    font-size: 0.75rem;
    width: 85%;
}

.social-media-icons-container{
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.social-media-icon-i{
    font-size: 2rem;
    color: var(--bgpink);
}

textarea:focus, input:focus{
    outline: none;
}

@media screen and (max-width:1110px) {
    #gmailid{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 55px;
    }
}

@media screen and (max-width:510px) {
    .Contactmain-container{
        height: calc(100% - 100px);
        flex-direction: column;
        justify-content: space-between;
    }

    #gmailid{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: initial;
    }

    .contact-form-row-span{
        font-size: 1.2rem;
    }

    .contact-form-message-span{
        font-size: 1.2rem;
    }

    .contact-form-container{
        width: 100%;
        height: 60%;
    }

    .contact-form-section{
        width: 100%;
    }

    #contact-form{
        width: 100%;
    }

    #contactinfo-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .icon-data-container{
        justify-content: flex-start;
    }

    .icon-data-container-i{
        text-align: center;
        font-size: 2rem;
    }

    .icon-data-container-p{
        font-size: 1.2rem;
        margin-left: 1%;
    }

    .social-media-icon-i{
        font-size: 3rem;
    }
}

@media screen and (max-width:290px){

    .Contactmain-container{
        height: calc(100% - 80px);
        flex-direction: column;
        justify-content: space-between;
    }

    .icon-data-container-p{
        font-size: 1rem;
        margin-left: 1%;
    }

    .icon-data-container-i{
        font-size: 1.5rem;
        margin-left: 1%;
    }

    .contact-form-row-span{
        font-size: 1rem;
    }

    .contact-form-message-span{
        font-size: 1rem;
    }

    .form-button{
        margin-left: 2.9%;
        width: 20%;
        height: 100%;
        font-weight: 400;
        letter-spacing: initial;
        font-size: 1rem;
    }

    #gmailid{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 55px;
    }
}