.AboutMain-container{
    background-color: var(--bgpink);
    color: white;
    display: flex;
    flex-direction: row;
    height: 80%;
    align-items: center;
    
}

.aboutsection{
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

h4{
    font-weight: 100;
    font-size: 1rem;
    margin-left: 10px;
}

#btndwn{
    text-align: end;
}

#dwncv{
    border: none;
    background-color: black;
    color: white;
    font-size: 1.1rem;
    width: 130px;
    height: 45px;
    position: relative;
    margin-right: 10px;
}

#dwncv:hover{
    cursor: pointer;
}

@media screen and (max-width: 510px){
    .AboutMain-container{
        height: calc(100% - 100px);
        flex-direction: column;
        overflow-y: scroll;
    }

    .aboutsection{
        min-height: 570px;
        width: 100%;
        align-items: center;
        margin: 5% auto;
    }

    h4{
        font-weight: 100;
        font-size: 1.5rem;
        width: 95%;
        margin: 10px auto;
    }

    #dwncv{
        color: white;
        font-size: 1.1rem;
        width: 150px;
        height: 55px;
        position: initial;
    }
}

@media screen and (max-width: 300px){
    .AboutMain-container{
        height: calc(100% - 80px);
        flex-direction: column;
        overflow-y: scroll;
    }

    h4{
        font-size: 1.2rem;
    }
}