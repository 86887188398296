.container{
    min-height: 300px;
}

.SkillsMain-container{
    height: 80%;
}

.Skillsection{
    height: 100%;
    display: flex;
    flex-direction: row;
    min-height: 300px;
}

.Sheading-container{
    width: 40%;
    background-color:  whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Sheading-container-icon{
    color: var(--bgpink);
    height: 30%;
    font-size: 3.5rem;
    width: 20%;
    margin-top: 5%;
}

.Sheading-container-h1{
    color:var(--bgpink);
    background-color: #b9b9b9;
    font-size: 2.5rem;
    padding: 10px;
    width: 100%;
    min-height: 40%;
}

.h1-span{
    color: #2E2B2A;
}

.skillcontent{
    width: 60%;
    background-color:  #2E2B2A;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media screen and (max-width: 510px){
    .practise-container{
        height: calc(100% - 100px);
    }

    .container{
        height: 100%;
    }
}

@media screen and (max-width: 300px){
    .practise-container{
        height: calc(100% - 80px);
    }
}