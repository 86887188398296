.icon-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: var(--gray);
    height: 100%;
}

.icon-container:hover{
    color: var(--bgpink);
    cursor: pointer;
    
}

#icon{
   font-size: 2rem;
}

.title{
    font-weight: 600;
}

@media screen and (max-width: 510px){
    .title{
        font-size: 0.7rem;
    }

    #icon{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 290px)
{
    .title{
        font-size: 0.6rem;
    }

    #icon{
        font-size: 2rem;
    }
}