.App-container{
    height: 65vh;
    width: 64vw;
    margin:auto;
    display: inline-block;
    transform: translate(18vw,15vh);
}

@media screen and (min-height:1000px){
    .App-container{
        height: 550px;
        transform: translate(18vw,calc((100vh - 550px)/2));
    }
}

@media screen and (max-width: 850px) {
    .App-container{
        width: 665px;
        transform: translate(calc((100vw - 665px)/2),calc((100vh - 550px)/2));
    }
}

@media screen and (max-width: 510px) {
    .App-container{
        width: 100vw;
        height: 100vh;
        display: inline-block;
        transform: none;
    }
}